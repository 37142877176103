import * as React from "react";

import { B2xResultMessage } from "@telia/b2x-result-message/react-cjs";
import { t } from "@telia/b2b-microcopy-translation";
import translationKeys from "../translation/translation-keys";
import { B2B_BASE_URL } from "@telia/b2b-utils";
import styles from "./Default.module.scss";


const { notAuthorized } = translationKeys;

const Default = () => {
  return (
    <div className={styles.container}>
      <B2xResultMessage
      heading={t(notAuthorized.heading)}
      completeDescription={t(notAuthorized.description)}
      graphic="access-rights"
      actionType="cta-link"
      linkHref={B2B_BASE_URL}
      actionLabel={t(notAuthorized.buttonText)}
      data-testid="b2b-not-authorized"
    />
    </div>
    
  );
};

export default Default;
