import React from "react";
import ReactDOMClient from "react-dom/client";
import singleSpaReact from "single-spa-react";
import Default from "./components/Default";
import { PAGE_READABLE_KEY } from "./translation/translation-keys";
import { TranslationLoader } from "@telia/b2b-microcopy-translation";

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  renderType: "createRoot",
  rootComponent: () => (
    <TranslationLoader resourceSetKeys={[PAGE_READABLE_KEY]}>
      <Default />
    </TranslationLoader>
  ),
});

export const { bootstrap, mount, unmount } = lifecycles;
