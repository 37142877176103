
export const PAGE_READABLE_KEY = "b2bnotauthorized";
const resource = PAGE_READABLE_KEY;

const translationKeys = {
  notAuthorized: {
    heading: `${resource}.notAuthorized.heading`,
    description: `${resource}.notAuthorized.description`,
    buttonText: `${resource}.notAuthorized.buttonText`,
  },
};

export default translationKeys;
