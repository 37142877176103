// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kLQ98f6VdE916yYQAKac{background-color:var(--purpur-color-gray-50)}`, "",{"version":3,"sources":["webpack://./src/components/Default.module.scss"],"names":[],"mappings":"AAEA,sBACI,4CAAA","sourcesContent":["@import \"~@purpurds/tokens/color/variables\";\n\n.container {\n    background-color: var(--purpur-color-gray-50);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `kLQ98f6VdE916yYQAKac`
};
export default ___CSS_LOADER_EXPORT___;
